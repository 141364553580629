@font-face {
    font-family: 'Roboto_Light';
    src: local('Roboto_Light'), url(../../../assets/fonts/Roboto_Condensed/RobotoCondensed-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto_Regular';
    src: local('Roboto_Regular'),
        url(../../../assets/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto_Bold';
    src: local('Roboto_Bold'), url(../../../assets/fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf) format('truetype');
}

@import '../../../assets/style/style.scss';

.footer {
    width: 100%;
    height: 6 * $basicWidth;
    background-color: #dbdbdb;
    background-image: url(../../../assets/icons/FOOTER_pattern.png);
    background-repeat: space repeat;
    background-size: 150px 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    padding-top: $basicWidth / 2;
}

#info,
#location {
    width: 7 * $basicWidth;
    height: 5 * $basicWidth;
    background-color: white;
    box-shadow: 0px 0px 15px grey;
}

#info {
    text-align: left;
    margin-right: $basicWidth * 4;
    padding-top: $basicWidth / 2;
    padding-left: $basicWidth;

    & img {
        height: 25px;
    }

    & h1 {
        font-family: Roboto_Light, Arial, serif;
        font-size: 18px;
        padding-left: 24px;
        margin: 0px;
        margin-top: 12px;
    }

    & #footerData {
        width: fit-content;
        height: auto;

        & .dataRow {
            display: flex;
            flex-direction: row;
            justify-content: right;
            align-items: center;

            & img {
                margin-right: 8px;
                width: 16px;
            }

            & img[alt='location'] {
                height: 20px;
            }

            & img[alt='phone'] {
                height: 20px;
            }

            & div {
                font-family: Roboto_Light, Arial, serif;
                font-size: 18px;
                margin: auto;
                margin-left: 0px;
                margin-top: 2px;
            }
        }

        & div:last-of-type {
            & img {
                height: 15px;
                margin-top: 8px;
            }
        }
    }

    & #copyright {
        font-family: Roboto_Bold, Arial, serif;
        font-size: 21px;
        margin-top: 20px;
        margin-left: 25px;
    }
}

@media only screen and (max-width: 1640px) {
    .footer {
        height: 275px;

        & #info,
        #location {
            width: 270px;
            height: 225px;
        }

        & #info {
            & h1 {
                font-size: 15px;
            }
            & #footerData {
                & .dataRow {
                    & div {
                        font-size: 15px;
                    }
                }
            }
            & #copyright {
                font-size: 17px;
            }
        }
    }
}

@media only screen and (max-width: 1460px) {
    .footer {
        height: 275px;
    }
}

@media only screen and (max-width: 1100px) {
    & .footer {
        & #info,
        #location {
            margin: auto;
            margin-bottom: $basicWidth;
        }
    }
}

@media only screen and (max-width: 800px) {
    .footer {
        flex-direction: column;
        height: 600px;
    }
}
