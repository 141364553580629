$basicWidth: 50px;

.primaryButton {
    width: 150px;
    height: 40px;
    margin-bottom: 30px !important;
    font-family: Roboto_Light, Arial, serif;
    font-size: 16px;
    font-weight: bold;
    color: red !important;
    background: white !important;
    border-color: red !important;
    cursor: pointer !important;
    border: 2px solid red !important ;
}

.secondaryButton {
    width: 150px;
    height: 40px;
    font-size: 16px;
    font-family: Roboto_Light, Arial, serif;
    font-weight: bold;
    cursor: pointer;
    background: lightgrey !important;
    color: black !important;
    border: 0px;
}

@keyframes slide_top {
    0% {
        top: -1000px;
    }
    100% {
        top: 110px;
    }
}

@keyframes slide_down {
    0% {
        height: 0px;
    }
    100% {
        height: 50px;
    }
}

@keyframes slide_left {
    0% {
        width: 0%;
    }
    100% {
        width: 25%;
    }
}
