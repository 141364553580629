@font-face {
    font-family: 'Roboto_Light';
    src: local('Roboto_Light'), url(../../assets/fonts/Roboto_Condensed/RobotoCondensed-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto_Regular';
    src: local('Roboto_Regular'),
        url(../../assets/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto_Bold';
    src: local('Roboto_Bold'), url(../../assets/fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf) format('truetype');
}

@import '../../assets/style/style.scss';

#coverImage {
    object-fit: cover;
    object-position: 0 50%;
    width: 100%;
    height: 300px;
    margin-top: 110px;
}

a{
    cursor: pointer;
}

.sectionWrapper {
    position: relative;
    text-align: end;

    & .sectionContainer {
        width: 55%;
        margin-top: 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: auto;
        margin-top: 50px;
        margin-bottom: 50px;

        & img {
            width: 50%;
            margin-right: 50px;
            object-fit: cover;
        }

        & #aboutImage {
            object-fit: cover;
        }

        & .sectionText {
            width: 55%;
            line-height: normal;
            font-family: Roboto_Light, Arial, serif;
            text-align: justify;
            font-size: 16px;

            & h1 {
                font-family: Roboto_Bold, Arial, serif;
                font-weight: bold;
                font-size: 28px;
                text-align: left;
                margin-top: -6px;
                margin-bottom: 40px;
            }

            & #contactLink {
                margin-bottom: 50px;
            }
        }
    }

    & .linkImage {
        height: auto;
        width: 400px;
        margin-bottom: $basicWidth;
    }
}

#standardsBottomImage {
    width: 100%;
}

.cookieAboutContainer {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 100px;
    background: rgba($color: #ffffff, $alpha: 0.9);
    animation: slide_top 1s ease-out;
    z-index: 100;

    & .aboutText {
        width: 55%;
        margin: auto;
        text-align: justify;
        font-family: Roboto_Regular, Arial, serif;
        font-size: 12px;
        padding-top: 50px;

        & h1 {
            font-size: 16px;
            font-family: Roboto_Bold, Arial, serif;
            margin: 0px;
        }

        & h2 {
            font-size: 12px;
            font-family: Roboto_Bold, Arial, serif;
            margin: 0px;
        }
    }
}

.cookieContainer {
    text-align: center;
    position: fixed;
    margin: auto;
    width: 100%;
    bottom: 0px;
    background: white;
    z-index: 100;

    & .controlsContainer {
        & .controlsText {
            padding-top: 15px;
            font-size: 12px;
            font-family: Roboto_Regular, Arial, serif;
        }

        & .controlsOptions {
            width: 50%;
            display: flex;
            padding-top: 25px;
            margin: auto;
            justify-content: space-evenly;
        }
    }
}

@media only screen and (max-width: 1640px) {
    .sectionWrapper {
        & .sectionContainer {
            & #aboutImage {
                height: unset;
            }

            & .sectionText {
                & img {
                    width: 30%;
                    margin-top: 50px;
                    margin-bottom: 0px;
                }

                & #contactLink {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1540px) {
    .cookieAboutContainer {
        & .aboutText {
            font-size: 12px;

            & h1 {
                font-size: 18px;
            }

            & h2 {
                font-size: 12px;
            }
        }
    }
}

@media only screen and (max-width: 1440px) {
    .sectionWrapper {
        & .sectionContainer {
            & #contactLink {
                margin-bottom: unset;
            }
        }
    }

    .cookieAboutContainer {
        & .aboutText {
            height: 60%;
            overflow-y: scroll;
        }
    }
}

@media only screen and (max-width: 1400px) {
    .sectionWrapper {
        & .sectionContainer {
            width: 750px;
        }
    }

    .cookieAboutContainer {
        & .aboutText {
            width: 750px;
        }
    }
}

@media (max-width: 1366px) {
    #coverImage {
        height: 250px;
    }
}

@media only screen and (max-width: 1260px) {
    .sectionWrapper {
        & .sectionContainer {
            flex-direction: column;
            width: 55%;

            & img {
                margin: auto;
                margin-bottom: 50px !important;
                width: 80%;
            }

            & .sectionText {
                margin: auto;
                width: 80%;

                & img {
                    width: 70%;
                    position: relative;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    #coverImage {
        height: 200px;
    }
}

@media only screen and (max-width: 1000px) {
    .sectionWrapper {
        & .sectionContainer {
            & .sectionText {
                & img {
                    margin-top: 30px;
                    width: 85%;
                }
            }
        }
    }

    .cookieAboutContainer {
        & .aboutText {
            & h1 {
                font-size: 15px;
            }
        }
    }

    .cookieContainer {
        & .controlsContainer {
            & .controlsText {
                font-size: 12px;
            }

            & .controlsOptions {
                & .primaryButton,
                .secondaryButton {
                    width: 120px;
                    height: 35px;
                    font-size: 15px;
                }
            }
        }
    }
}

@media only screen and (max-width: 860px) {
    .cookieContainer {
        & .controlsContainer {
            & .controlsOptions {
                width: 80%;
            }
        }
    }
}

@media only screen and (max-width: 780px) {
    .sectionWrapper {
        & .sectionContainer {
            width: 70%;

            & .sectionText {
                & img {
                    width: 100%;
                }
            }
        }
    }

    .cookieAboutContainer {
        & .aboutText {
            width: 95%;
        }
    }
}

@media only screen and (max-width: 650px) {
    .cookieAboutContainer {
        & .aboutText {
            font-size: 11px;
        }
    }

    .cookieContainer {
        & .controlsContainer {
            & .controlsText {
                font-size: 11px;
            }
        }
    }
}

@media only screen and (max-width: 580px) {
    .sectionWrapper {
        & .sectionContainer {
            width: 100%;
            margin-top: 50px;

            & .sectionText {
                font-size: 14px;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .sectionWrapper {
        & .linkImage {
            width: 300px;
        }
    }

    .cookieAboutContainer {
        top: 90px;

        & .aboutText {
            width: 92%;
            height: 70%;
            overflow-y: scroll;
        }
    }

    .cookieAboutContainer:focus-within {
        overflow: scroll;

        .App {
            overflow: hidden;
        }
    }

    .cookieContainer {
        & .controlsContainer {
            & .controlsOptions {
                width: 95%;

                & .primaryButton,
                .secondaryButton {
                    width: 100px;
                    height: 30px;
                    font-size: 12px;
                }
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    #coverImage,
    #standardsBottomImage {
        height: 150px;
    }
}

@media only screen and (max-width: 320px) {
    .cookieAboutContainer {
        & .aboutText {
            width: 88%;
            height: 50%;
        }
    }
}
