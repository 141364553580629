@font-face {
    font-family: 'Roboto_Light';
    src: local('Roboto_Light'), url(../../../assets/fonts/Roboto_Condensed/RobotoCondensed-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto_Regular';
    src: local('Roboto_Regular'),
        url(../../../assets/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto_Bold';
    src: local('Roboto_Bold'), url(../../../assets/fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf) format('truetype');
}

@import '../../../assets/style/style.scss';

.headerContainer {
    width: 100%;
    margin-bottom: $basicWidth;
    font-family: Roboto_Bold, Arial, serif;
    background-color: white;
    position: fixed;
    height: 110px;
    z-index: 200;
    display: flex;
    justify-content: center;

    & .headerContent {
        width: 55%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & .logo {
            height: 26px;
            margin-top: 30px;
            cursor: pointer;
            margin-right: 5px;
        }

        & .link {
            position: relative;
            margin-top: 35px;
            font-size: 18px;
            color: #1a1a1a;
            font-weight: bold;
            font-style: unset;
            text-decoration: unset;

            & #aboutDropdown {
                width: 100%;
                height: 35px;
                position: absolute !important;
                position: fixed;
                font-size: 16px;
                font-family: Roboto_Bold, Arial, serif;
                cursor: pointer;
                left: -9px;
                background-color: white;
                opacity: 0.96;

                & p {
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    margin: auto;
                    margin-top: 5px;
                    color: #1a1a1a;
                    opacity: 0.5;
                }

                & p:hover {
                    opacity: 1;
                }
            }

            & #projectsDropdown {
                width: 100%;
                display: grid;
                grid-template-columns: 70px 70px;
                position: absolute !important;
                margin-top: 5px;
                background-color: white;
                background: transparent;
                z-index: 100;
                opacity: 0.96;

                & div {
                    width: 100%;
                    height: 35px;
                    margin: auto;
                    margin-bottom: 5px;
                    cursor: pointer;
                    font-size: 16px;
                    font-family: Roboto_Bold, Arial, serif;
                    background: white;

                    & a {
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        margin: auto;
                        padding-top: 5px;
                        color: #1a1a1a;
                        opacity: 0.5;
                        font-size: 16px;
                    }

                    & a:hover {
                        opacity: 1;
                    }
                }
            }
        }

        & .blured {
            opacity: 0.5;
        }

        & .activeLink {
            color: black;
            opacity: 1;
        }
    }

    & hr {
        width: 100%;
        position: absolute;
        top: 40px;
    }

    & .hidden {
        display: none;
    }
}

@media only screen and (max-width: 1400px) {
    .headerContainer {
        & .headerContent {
            width: 15 * $basicWidth;

            & .logo {
                height: 24px;
                margin-top: 32px;
            }
        }
    }
}

@media only screen and (max-width: 1250px) {
    .headerContainer {
        & .headerContent {
            & .logo {
                height: 20px;
                margin-top: 33px;
            }

            & .link {
                margin-top: 33px;
                margin-left: 20px;

                & #aboutDropdown {
                    width: 71px;
                    left: 12px;

                    & p {
                        font-size: 16px;
                    }
                }

                & #projectsDropdown {
                    width: 171px;
                    left: 20px;

                    & div {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .headerContainer {
        & .headerContent {
            & .logo {
                height: 16px;
                margin-top: 32px;
            }

            & .link {
                font-size: 16px;
                margin-top: 31px;

                & #aboutDropdown {
                    width: 62px;
                    height: 35px;

                    & p {
                        margin-top: 8px;
                        font-size: 13px;
                    }
                }

                & #projectsDropdown {
                    width: 152px;
                    left: 2px;
                    margin-top: 8px;

                    & div {
                        font-size: 13px;
                        text-align: center;

                        & a {
                            margin-top: 8px;
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 780px) {
    .headerContainer {
        & .mobileHeaderContent {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            text-align: center;
            position: relative;

            & .logo {
                position: absolute;
                height: 20px;
                top: 35px;
                left: 20px;
                cursor: pointer;
            }

            & i {
                position: absolute;
                right: 20px;
                top: 30px;
                font-size: 25px;
                cursor: pointer;
            }
        }
        & .menuContainer {
            width: 100%;
            position: fixed;
            top: 70px;
            background-color: white;
            right: 0;
            display: flex;
            flex-direction: column;

            & .linkRow {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                background-color: #6ca3a3;
                color: white;

                & a {
                    text-decoration: none;
                    width: 100%;
                    height: 50px;
                    text-align: left;
                    font-size: 20px;
                    font-weight: bold;
                    padding-left: 15px;
                    padding-top: 10px;
                    font-family: Roboto_Bold, Arial, serif;
                }

                & i {
                    font-size: 25px;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }

            & .dropdownLink {
                text-decoration: none;
                background-color: rgb(100, 98, 98);
                color: white;
                padding-left: 40px;
                height: 0px;
                animation-name: slide_down;
                animation-delay: 0.2s;
                animation-duration: 0.8s;
                animation-fill-mode: forwards;
                animation-timing-function: ease-out;
                cursor: pointer;
            }
        }
    }
}
