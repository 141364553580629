@font-face {
    font-family: 'Roboto_Light';
    src: local('Roboto_Light'), url(../../assets/fonts/Roboto_Condensed/RobotoCondensed-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto_Regular';
    src: local('Roboto_Regular'),
        url(../../assets/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto_Bold';
    src: local('Roboto_Bold'), url(../../assets/fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf) format('truetype');
}

@import '../../assets/style/style.scss';

.contactContainer {
    width: 100%;
    padding-top: 120px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: Roboto_Light, Arial, serif;

    & .formContainer {
        width: 560px;
        margin-bottom: 50px;
        line-height: normal;

        > div:first-of-type,
        > div:last-of-type {
            font-family: Roboto_Bold, Arial, serif;
            font-size: 28px;
            margin: 0px;
        }

        & label {
            font-family: Roboto_Light, Arial, serif;
            font-size: 16px;
        }

        & input {
            width: 100%;
            height: 25px;
            font-size: 16px;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        & textarea {
            width: 100%;
            height: 70px;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        & div {
            margin-top: 10px;
            margin-bottom: 30px;
        }

        & button {
            width: 150px;
            height: 35px;
            margin-bottom: 30px;
            font-family: Roboto_Light, Arial, serif;
            font-size: 21px;
            color: red;
            background: white;
            border-color: red;
            cursor: pointer;
        }

        & .disabledButton {
            cursor: auto;
            color: grey;
            border-color: grey;
            opacity: 0.5;
        }

        & input[type='checkbox'] {
            width: 22px;
            margin-top: 10px;
            margin-bottom: 30px;
        }

        & #agreementText {
            font-family: Roboto_Light, Arial, serif;
            font-size: 16px;
            text-align: justify;
            margin-bottom: 50px;
        }

        & h1 {
            font-family: Roboto_Bold, Arial, serif;
            font-size: 28px;
            margin: 0px;
        }
    }
}

.notification {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 0%;
    height: 7%;
    background-color: rgb(113, 212, 113);
    color: white;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    animation-name: slide_left;
    animation-delay: 0.2s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
}

.errorNotification {
    background-color: rgb(211, 62, 62);
}

@media only screen and (max-width: 680px) {
    .contactContainer {
        & .formContainer {
            padding-left: 5px;
            padding-right: 5px;

            > div:last-of-type {
                text-align: center;
                margin-left: unset;
            }
        }
    }
}
