@font-face {
    font-family: 'Roboto_Light';
    src: local('Roboto_Light'), url(../../assets/fonts/Roboto_Condensed/RobotoCondensed-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto_Regular';
    src: local('Roboto_Regular'),
        url(../../assets/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto_Bold';
    src: local('Roboto_Bold'), url(../../assets/fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf) format('truetype');
}

.projectsContainer {
    width: 100%;
    padding-top: 120px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-image: url(../../assets/icons/FOOTER_pattern2.png);
    background-repeat: space repeat;
    background-size: 150px 150px;

    & .projectsGrid {
        width: 55%;
        grid-gap: 50px;

        & .project {
            display: grid;
            grid-template-columns: 50% 50%;
            font-size: 26px;
            margin-bottom: 50px;

            & img {
                width: 100%;
                height: 300px;
                object-fit: cover;
            }

            & .title {
                margin: auto;
                margin-left: 0px;
                font-family: Roboto_Bold, Arial, serif;

                & img {
                    width: 25px;
                    height: 30px;
                    margin-right: 20px;
                    margin-bottom: 15px;
                    margin-left: 50px;
                }

                & p {
                    font-size: 16px;
                    font-weight: bold;
                    margin: 0px;
                    margin-left: 95px;
                    font-family: Roboto_Light, Arial, serif;
                }
            }
        }

        & .primaryGrey {
            background-color: #dbdbdb;
        }

        & .secondaryGrey {
            background-color: #fafafa;
        }

        & .project:first-of-type {
            & .title {
                & img {
                    width: 35px;
                    margin-right: 10px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
    .projectsContainer {
        & .projectsGrid {
            & .project {
                & img {
                    height: 250px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .projectsContainer {
        & .projectsGrid {
            width: 750px;

            & .project {
                & .title {
                    & p {
                        font-size: 16px;
                    }
                }

                & img {
                    height: 200px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1050px) {
    .projectsContainer {
        & .projectsGrid {
            width: 55%;
            grid-template-columns: unset;

            & .project {
                grid-template-columns: unset;
                grid-gap: 25px;

                & img {
                    height: 300px;
                }

                & .title {
                    margin: auto;
                    padding-top: 20px;

                    & img {
                        margin-bottom: 15px;
                        margin-left: 0px;
                    }

                    & p {
                        margin-left: 45px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .projectsContainer {
        & .projectsGrid {
            & .project {
                & img {
                    height: 200px;
                }
            }
        }
    }
}

@media only screen and (max-width: 450px) {
    .projectsContainer {
        & .projectsGrid {
            & .project {
                & .title {
                    & img {
                        width: 24px;
                        margin-right: 10px;
                    }

                    & p {
                        margin-left: 35px;
                    }
                }

                & img {
                    margin: auto;
                    height: 150px;
                }
            }
        }
    }
}
